<template>
    <footer class="container-fluid bg-secondary py-1 align-self-end">
        <router-link to="/aviso-privacidad" class="link-light px-5">Aviso de Privacidad</router-link>
        <span class="text-light m-0 px-3">Web-Sait SA de CV &copy; {{añoActual}}</span>
    </footer>
</template>

<script>

export default {
  name: 'Footer',
  computed: {
    añoActual(){
      return new Date().getFullYear();
    }
  }
  /*props: {
    
  },
  setup(props){
    
  }*/
}
</script>

<style scoped>
</style>